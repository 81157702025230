import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Home from './pages/Home'
import { routerConfig, routes } from './config/router.config'

const router = createBrowserRouter(
  routes.map(route => ({
    ...route,
    element: route.path === '/' ? <Home /> : route.element
  })),
  routerConfig
)

const App: React.FC = () => {
  return <RouterProvider router={router} />
}

export default App 