import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { motion, AnimatePresence } from 'framer-motion'
import { FaGithub, FaLinkedin, FaTwitter, FaExternalLinkAlt } from 'react-icons/fa'
import { BiCode, BiCloud, BiMobile, BiData, BiChevronRight } from 'react-icons/bi'
import ParticlesBackground from '../components/ParticlesBackground'
import confetti from 'canvas-confetti'
import SuccessMessage from '../components/SuccessMessage'

// Estilos reutilizables
const glassEffect = "backdrop-blur-md bg-white/5 border border-white/10"
const gradientText = "bg-gradient-to-r from-primary to-blue-400 bg-clip-text text-transparent"
const cardHover = "hover:scale-105 hover:shadow-xl hover:shadow-primary/20 transition-all duration-300"

const Home: React.FC = () => {
  return (
    <div className="relative">
      {/* Fondo dinámico */}
      <div className="fixed inset-0 bg-dark">
        <div className="absolute inset-0 bg-gradient-to-br from-primary/10 via-dark to-dark"></div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
        <ParticlesBackground />
      </div>

      {/* Contenido principal */}
      <div className="relative">
        <Header />
        
        <main className="relative min-h-screen text-white">
          {/* Hero Section */}
          <section className="min-h-screen flex items-center justify-center">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="container mx-auto px-4 text-center"
            >
              <motion.h1 
                className="text-5xl md:text-7xl font-bold mb-6"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <span className={gradientText}>Reyner González</span>
              </motion.h1>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <p className="text-xl md:text-2xl text-gray-300 mb-8">
                  Consultor Estratégico en Innovación & Tecnología
                </p>
                <p className="text-lg md:text-xl text-gray-400 max-w-3xl mx-auto mb-12">
                  Potenciando el crecimiento empresarial a través de estrategias tecnológicas 
                  innovadoras, gestión de proyectos de alto impacto y soluciones digitales 
                  que generan valor real.
                </p>
              </motion.div>
              
              <div className="flex justify-center gap-6 mb-12">
                <SocialLink href="https://github.com" icon={<FaGithub />} />
                <SocialLink href="https://linkedin.com" icon={<FaLinkedin />} />
                <SocialLink href="https://twitter.com" icon={<FaTwitter />} />
              </div>

              <motion.div 
                className="flex flex-col sm:flex-row justify-center gap-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <ActionButton href="#contact" primary>
                  <span className="flex items-center gap-2">
                    Agenda una Consultoría
                    <motion.span
                      animate={{ x: [0, 5, 0] }}
                      transition={{ duration: 1.5, repeat: Infinity }}
                    >
                      →
                    </motion.span>
                  </span>
                </ActionButton>
                <ActionButton href="#services">
                  <span className="flex items-center gap-2">
                    Explora mis Servicios
                    <motion.div
                      animate={{ rotate: [0, 15, 0] }}
                      transition={{ duration: 2, repeat: Infinity }}
                    >
                      ✦
                    </motion.div>
                  </span>
                </ActionButton>
              </motion.div>

              {/* Badges de expertise con efecto de ola */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
                className="mt-16 flex flex-wrap justify-center gap-4"
              >
                {[
                  'Gestión de Proyectos',
                  'Innovación Digital',
                  'Desarrollo de Software',
                  'Ciberseguridad'
                ].map((badge, index) => (
                  <motion.span
                    key={badge}
                    className={`${glassEffect} px-4 py-2 rounded-full text-sm text-gray-300`}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ 
                      opacity: 1,
                      scale: 1,
                      y: [-2, -15, -2],
                    }}
                    transition={{
                      y: {
                        duration: 2.5,
                        repeat: Infinity,
                        ease: "easeInOut",
                        delay: index * 0.2
                      },
                      opacity: { duration: 0.5, delay: 0.7 + index * 0.1 },
                      scale: { duration: 0.5, delay: 0.7 + index * 0.1 }
                    }}
                    whileHover={{ 
                      scale: 1.1,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      transition: { duration: 0.2 }
                    }}
                  >
                    {badge}
                  </motion.span>
                ))}
              </motion.div>
            </motion.div>
          </section>

          {/* Servicios Section */}
          <section id="servicios" className="py-20">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="container mx-auto px-4"
            >
              <h2 className="text-4xl font-bold text-center mb-16">
                <span className={gradientText}>Servicios</span> Estratégicos
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <ServiceCard 
                  icon={<BiCode />}
                  title="Gestión de Proyectos Tecnológicos"
                  description="Metodologías ágiles y tradicionales adaptadas a tus necesidades."
                  features={[
                    "Planificación estratégica",
                    "Control de calidad",
                    "Gestión de riesgos",
                    "Optimización de recursos"
                  ]}
                />
                <ServiceCard 
                  icon={<BiCloud />}
                  title="Desarrollo de Software"
                  description="Soluciones tecnológicas escalables y mantenibles."
                  features={[
                    "Arquitectura moderna",
                    "Código de calidad",
                    "Seguridad integrada",
                    "Experiencia de usuario"
                  ]}
                />
                <ServiceCard 
                  icon={<BiMobile />}
                  title="Innovación Digital"
                  description="Transformación e implementación de nuevas tecnologías."
                  features={[
                    "Análisis de oportunidades",
                    "Roadmap tecnológico",
                    "Proof of Concept",
                    "Escalabilidad"
                  ]}
                />
                <ServiceCard 
                  icon={<BiData />}
                  title="Ciberseguridad"
                  description="Protección integral de tus activos digitales."
                  features={[
                    "Evaluación de riesgos",
                    "Implementación de controles",
                    "Capacitación",
                    "Monitoreo continuo"
                  ]}
                />
              </div>
            </motion.div>
          </section>

          {/* Applicativa.xyz Section */}
          <section className="py-20">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="container mx-auto px-4"
            >
              <div className={`${glassEffect} rounded-2xl p-8 md:p-12`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                  <div>
                    <motion.h2 
                      className="text-4xl font-bold mb-6"
                      whileHover={{ scale: 1.05 }}
                    >
                      <span className={gradientText}>applicativa</span>
                      <span className="text-white">.xyz</span>
                    </motion.h2>
                    <p className="text-xl text-gray-300 mb-6">
                      Llevando mi visión de consultoría al siguiente nivel
                    </p>
                    <p className="text-gray-400 mb-8">
                      Una plataforma innovadora que democratiza el acceso a servicios de consultoría 
                      tecnológica de alta calidad, permitiendo a empresas de todos los tamaños 
                      beneficiarse de soluciones personalizadas y acompañamiento experto.
                    </p>
                    <div className="space-y-4 mb-8">
                      <FeatureItem 
                        title="Consultoría Accesible"
                        description="Servicios profesionales adaptados a diferentes presupuestos"
                      />
                      <FeatureItem 
                        title="Metodología Probada"
                        description="Implementación de mejores prácticas y procesos optimizados"
                      />
                      <FeatureItem 
                        title="Tecnología de Vanguardia"
                        description="Acceso a las últimas innovaciones y tendencias tecnológicas"
                      />
                    </div>
                    
                    <motion.a
                      href="https://applicativa.xyz"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`
                        inline-flex items-center gap-3 
                        px-8 py-4 rounded-xl
                        bg-gradient-to-r from-primary to-primary/80
                        hover:from-primary/90 hover:to-primary/70
                        text-white font-semibold
                        shadow-lg shadow-primary/20
                        transition-all duration-300
                        ${glassEffect}
                      `}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <span>Visitar applicativa.xyz</span>
                      <motion.span
                        animate={{ x: [0, 4, 0] }}
                        transition={{ 
                          duration: 1.5, 
                          repeat: Infinity,
                          ease: "easeInOut" 
                        }}
                      >
                        <FaExternalLinkAlt className="text-sm" />
                      </motion.span>
                    </motion.a>
                  </div>
                  <div className="relative">
                    <div className="absolute -inset-1 bg-gradient-to-r from-primary/30 to-blue-500/30 rounded-2xl blur-xl"></div>
                    <div className={`${glassEffect} rounded-xl p-8 relative`}>
                      <h3 className="text-2xl font-bold mb-6">Beneficios Clave</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <BenefitCard 
                          title="Automatización"
                          description="Procesos optimizados"
                        />
                        <BenefitCard 
                          title="Análisis de Datos"
                          description="Decisiones informadas"
                        />
                        <BenefitCard 
                          title="Seguridad"
                          description="Protección integral"
                        />
                        <BenefitCard 
                          title="Innovación"
                          description="Ventaja competitiva"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </section>

          {/* Contact Section */}
          <ContactSection />
        </main>

        <Footer />
      </div>
    </div>
  )
}

// Componentes auxiliares
const ServiceCard: React.FC<{ 
  icon: React.ReactNode; 
  title: string; 
  description: string;
  features: string[];
}> = ({ icon, title, description, features }) => (
  <motion.div 
    className={`${glassEffect} ${cardHover} p-8 rounded-xl relative overflow-hidden group`}
    whileHover={{ y: -5 }}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <motion.div 
      className="absolute top-0 right-0 w-32 h-32 bg-primary/10 rounded-full blur-3xl"
      animate={{
        scale: [1, 1.2, 1],
        opacity: [0.5, 0.8, 0.5],
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        ease: "easeInOut"
      }}
    />
    <div className="relative z-10">
      <motion.div 
        className="text-4xl text-primary mb-4"
        whileHover={{ scale: 1.1, rotate: 5 }}
      >
        {icon}
      </motion.div>
      <h3 className="text-xl font-bold mb-3 group-hover:text-primary transition-colors">
        {title}
      </h3>
      <p className="text-gray-400 mb-6">{description}</p>
      <ul className="space-y-2">
        {features.map((feature, index) => (
          <motion.li 
            key={index}
            className="flex items-center text-gray-300"
            initial={{ opacity: 0, x: -10 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <motion.div
              whileHover={{ scale: 1.2, x: 2 }}
              className="text-primary mr-2"
            >
              <BiChevronRight />
            </motion.div>
            {feature}
          </motion.li>
        ))}
      </ul>
    </div>
  </motion.div>
)

const FeatureItem: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <div className="flex gap-4">
    <div className="text-primary">
      <BiChevronRight className="h-6 w-6" />
    </div>
    <div>
      <h4 className="font-semibold text-white">{title}</h4>
      <p className="text-gray-400">{description}</p>
    </div>
  </div>
)

const BenefitCard: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <div className={`${glassEffect} p-4 rounded-lg`}>
    <h4 className="font-semibold text-white mb-2">{title}</h4>
    <p className="text-gray-400 text-sm">{description}</p>
  </div>
)

const SocialLink: React.FC<{ href: string; icon: React.ReactNode }> = ({ href, icon }) => (
  <a 
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-2xl text-gray-400 hover:text-primary transition-colors"
  >
    {icon}
  </a>
)

const ActionButton: React.FC<{ 
  href: string; 
  children: React.ReactNode;
  primary?: boolean;
}> = ({ href, children, primary }) => (
  <a 
    href={href}
    className={`
      px-8 py-3 rounded-lg transition-all duration-300
      ${primary 
        ? 'bg-primary hover:bg-primary/90 text-white' 
        : `${glassEffect} hover:bg-white/10 text-gray-300 hover:text-white`
      }
    `}
  >
    {children}
  </a>
)

const ContactSection: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      // Simular envío (reemplazar con tu lógica real de envío)
      await new Promise(resolve => setTimeout(resolve, 1000))

      // Lanzar confeti
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      })

      setShowSuccess(true)
    } catch (error) {
      console.error('Error al enviar el formulario:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <section id="contact" className="py-20">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="container mx-auto px-4"
      >
        <h2 className="text-4xl font-bold text-center mb-16">
          Iniciemos tu <span className={gradientText}>Proyecto</span>
        </h2>
        <div className="max-w-2xl mx-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              <input 
                type="text" 
                placeholder="Nombre"
                required
                className={`w-full px-4 py-3 rounded-lg ${glassEffect} focus:border-primary focus:outline-none transition-all duration-300 focus:ring-2 focus:ring-primary/50`}
              />
            </motion.div>
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <input 
                type="email" 
                placeholder="Email"
                required
                className={`w-full px-4 py-3 rounded-lg ${glassEffect} focus:border-primary focus:outline-none transition-all duration-300 focus:ring-2 focus:ring-primary/50`}
              />
            </motion.div>
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <textarea 
                placeholder="Mensaje"
                required
                rows={5}
                className={`w-full px-4 py-3 rounded-lg ${glassEffect} focus:border-primary focus:outline-none transition-all duration-300 focus:ring-2 focus:ring-primary/50`}
              ></textarea>
            </motion.div>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <button 
                type="submit"
                disabled={isLoading}
                className={`
                  w-full bg-primary hover:bg-primary/90 text-white px-8 py-3 rounded-lg
                  transition-all duration-300 transform hover:scale-[1.02]
                  disabled:opacity-50 disabled:hover:scale-100
                  flex items-center justify-center
                `}
              >
                {isLoading ? (
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    className="w-6 h-6 border-2 border-white border-t-transparent rounded-full"
                  />
                ) : (
                  'Enviar Mensaje'
                )}
              </button>
            </motion.div>
          </form>
        </div>
      </motion.div>

      {/* Mensaje de éxito */}
      <AnimatePresence>
        {showSuccess && (
          <SuccessMessage onClose={() => setShowSuccess(false)} />
        )}
      </AnimatePresence>
    </section>
  )
}

export default Home 