import React from 'react'
import { motion } from 'framer-motion'

interface LogoProps {
  size?: 'sm' | 'md' | 'lg'
  animated?: boolean
}

const Logo: React.FC<LogoProps> = ({ size = 'md', animated = true }) => {
  const sizes = {
    sm: 'text-2xl',
    md: 'text-3xl',
    lg: 'text-4xl'
  }

  const LogoContent = () => (
    <>
      <span className="font-bold">RG</span>
      <span className="text-primary">.</span>
      <motion.span
        className="absolute -top-1 -right-1 w-2 h-2 bg-primary rounded-full"
        animate={animated ? {
          scale: [1, 1.2, 1],
          opacity: [0.5, 1, 0.5]
        } : undefined}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
    </>
  )

  return animated ? (
    <motion.div
      className={`relative inline-flex items-center ${sizes[size]}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <LogoContent />
    </motion.div>
  ) : (
    <div className={`relative inline-flex items-center ${sizes[size]}`}>
      <LogoContent />
    </div>
  )
}

export default Logo 