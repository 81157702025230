import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import showConsoleMessage from './utils/console-message'

// Mostrar mensaje en consola solo en desarrollo
if (import.meta.env.DEV) {
  showConsoleMessage()
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
) 