import type { RouteObject } from 'react-router-dom'

// Definir todas las opciones futuras disponibles en React Router v7
type RouterFutureConfig = {
  v7_startTransition: true
  v7_normalizeFormMethod: true
  v7_prependBasename: true
  v7_relativeSplatPath: true
  v7_fetcherPersist: true
  v7_partialHydration: true
  v7_skipActionErrorRevalidation: true
}

// Definir las opciones de configuración del router
type RouterConfig = {
  future: RouterFutureConfig
  basename?: string
}

// Configuración de las opciones futuras
export const routerFutureConfig: RouterFutureConfig = {
  v7_startTransition: true,
  v7_normalizeFormMethod: true,
  v7_prependBasename: true,
  v7_relativeSplatPath: true,
  v7_fetcherPersist: true,
  v7_partialHydration: true,
  v7_skipActionErrorRevalidation: true
}

// Configuración completa del router
export const routerConfig: RouterConfig = {
  future: routerFutureConfig
}

// Definición de rutas
export const routes: RouteObject[] = [
  {
    path: '/',
    element: null // Se establecerá en App.tsx
  }
] 